import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deriveEth2ValidatorKeys, deriveKeyFromMnemonic, eth2ValidatorPaths, deriveKeyFromEntropy } from '@chainsafe/bls-keygen';
import { init, SecretKey } from '@chainsafe/bls';
import { generateBLSWithdrawalDepositData, generateEth1WithdrawalDepositData, getToolsVersions } from 'deposit.js';
import JSZip from 'jszip';
import { create } from '@chainsafe/bls-keystore';
import { DepositJsData } from 'deposit.js/dist/src/types';
import { saveAs } from 'file-saver';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import { Button, Collapse, Divider, Form, Input, InputNumber, List, Popover, Progress, Radio, Tooltip, Typography } from 'antd';
import { Welcome } from './steps/Welcome';
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import manifest from '../package.json';

const MESSAGE = 'By signing this message, I certify my Ethereum address to deposit.js';

const WithdrawalModeDetails = {
  ethereum_address: {
    description: 'Your funds will be claimable by your ethereum address',
    nextStep: 'Select Ethereum Address'
  },
  bls: {
    description: 'Your funds will be claimable by the withdrawal key',
    nextStep: 'Withdrawal Credentials Ready'
  },
}

const RecoveryModeDetails = {
  user_mnemonic: {
    description: 'Use a mnemonic you own.',
    nextStep: 'Input Mnemonic'
  },
  new_mnemonic: {
    description: 'Generate a brand new mnemonic.',
    nextStep: 'Generate New Mnemonic'
  },
  wallet_signature: {
    description: 'Use your wallet signature to generate keys.',
    nextStep: 'Generate Signature'
  }
}

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function App() {

  const { active, account, library, activateBrowserWallet } = useEthers();
  const [withdrawalMode, setWithdrawalMode] = useState(null);
  const [eth1Withdrawal, setEth1Withdrawal] = useState(null);
  const [recoveryMode, setRecoveryMode] = useState(null);
  const [userMnemonic, setUserMnemonic] = useState('');
  const [validMnemonic, setValidMnemonic] = useState(false);
  const [generateMnemonicError, setGenerateMnemonicError] = useState(null);
  const [genCount, setGenCount] = useState(1);
  const [validatorIdx, setValidatorIdx] = useState(0);
  const [validatorPassword, setValidatorPassword] = useState('');
  const [validatorPasswordVerification, setValidatorPasswordVerification] = useState('');
  const [signature, setSignature] = useState(null);
  const [signatureError, setSignatureError] = useState(null);
  const [generationStep, setGenerationStep] = useState(0);
  const [step, setStep] = useState(0);
  const query = useQuery();
  const redirect = useMemo(() => {
    const redirectValue = query.get('redirect');
    if (redirectValue) {
      try {
        return decodeURI(redirectValue)
      } catch (e) {
        return null
      }
    }
    return null
  }, [query])
  const network = useMemo(() => {
    const networkValue = query.get('network');
    if (networkValue && ['mainnet', 'pyrmont', 'prater'].indexOf(networkValue) !== -1) {
      try {
        return networkValue 
      } catch (e) {
        return 'mainnet'
      }
    }
    return 'mainnet'
  }, [query])

  useEffect(() => {
    setEth1Withdrawal(account);
  }, [account]);

  const reset = useCallback(() => {
    setStep(0);
    setGenerationStep(0);
    setSignatureError(null);
    setValidatorPasswordVerification('');
    setValidatorPassword('');
    setValidatorIdx(0);
    setGenCount(1);
    setGenerateMnemonicError(null);
    setValidMnemonic(false);
    setUserMnemonic('');
    setRecoveryMode(null);
    setWithdrawalMode(null);
    setEth1Withdrawal(null);
  }, []);

  const signMessage = useCallback(async () => {
    if (library) {
      try {
        const sig = await library.getSigner().signMessage(MESSAGE);
        const signer = ethers.utils.verifyMessage(MESSAGE, sig);
        if (ethers.utils.getAddress(signer) !== ethers.utils.getAddress(account)) {
          setSignatureError(`Signature error: signer is not matching the provided address`)
        } else {
          setSignatureError(null);
          setSignature(sig);
        }
      } catch (e) {
        setSignatureError(`Signature error: unable to retrieve signature`)
      }
    }
  }, [library, account]);

  useEffect(() => {
    try {
      ethers.Wallet.fromMnemonic(userMnemonic);
      setValidMnemonic(true);
    } catch (e) {
      setValidMnemonic(false);
    }
  }, [userMnemonic])

  const generateMnemonic = useCallback(async () => {
    if (window.crypto) {
      const entropy = '0x' + Buffer.from(window.crypto.getRandomValues(Buffer.from([...new Array(32)]))).toString('hex')
      const mnemonic = ethers.utils.entropyToMnemonic(entropy, ethers.wordlists.en);
      setUserMnemonic(mnemonic);
    } else {
      setGenerateMnemonicError(`Mnemonic generation error: your browser doesn't support the crypto standard.`)
    }
  }, []);

  useEffect(() => {
    if (recoveryMode === 'new_mnemonic') {
      generateMnemonic();
    } else {
      setUserMnemonic('');
    }
  }, [recoveryMode, generateMnemonic]);


  const generate = useCallback(async () => {
    await init("herumi");

    const zip = new JSZip();
    const now = Math.floor(Date.now() / 1000);

    const depositDatum: DepositJsData[] = [];

    switch (recoveryMode) {
      case 'wallet_signature': {
        const childSecretKey = deriveKeyFromEntropy(signature);
        for (let idx = validatorIdx; idx < validatorIdx + genCount; ++idx) {
          const eth2 = deriveEth2ValidatorKeys(childSecretKey, idx);
          const path = eth2ValidatorPaths(idx);
          const secretKey = SecretKey.fromBytes(eth2.signing);
          const keystore = await create(validatorPassword, secretKey.toBytes(), secretKey.toPublicKey().toBytes(), path.signing, `Ethereum 2.0 Validator key generated with deposit.js. Index #${idx}.`);
          let depositData;
          if (withdrawalMode === 'ethereum_address') {
            depositData = await generateEth1WithdrawalDepositData(secretKey, network as any, eth1Withdrawal);
          } else {
            depositData = await generateBLSWithdrawalDepositData(secretKey, network as any, SecretKey.fromBytes(eth2.withdrawal));
          }
          zip.file(`keystore-${path.signing.replaceAll('/', '_')}-${now}.json`, JSON.stringify(keystore, null, 4))
          depositDatum.push(depositData);
          setGenerationStep(idx + 1);
        }
        break;
      }
      default: {
        const childSecretKey = deriveKeyFromMnemonic(userMnemonic);
        for (let idx = validatorIdx; idx < validatorIdx + genCount; ++idx) {
          const eth2 = deriveEth2ValidatorKeys(childSecretKey, idx);
          const path = eth2ValidatorPaths(idx);
          const secretKey = SecretKey.fromBytes(eth2.signing);
          const keystore = await create(validatorPassword, secretKey.toBytes(), secretKey.toPublicKey().toBytes(), path.signing, `Ethereum 2.0 Validator key generated with deposit.js. Index #${idx}.`);
          let depositData;
          if (withdrawalMode === 'ethereum_address') {
            depositData = await generateEth1WithdrawalDepositData(secretKey, network as any, eth1Withdrawal);
          } else {
            depositData = await generateBLSWithdrawalDepositData(secretKey, network as any, SecretKey.fromBytes(eth2.withdrawal));
          }
          zip.file(`keystore-${path.signing.replaceAll('/', '_')}-${now}.json`, JSON.stringify(keystore, null, 4))
          depositDatum.push(depositData);
          setGenerationStep(idx + 1);
        }
        break;
      }
    }

    zip.file(`deposit_data-${now}.json`, JSON.stringify(depositDatum, null, 4));
    setGenerationStep(genCount + 1);

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `validator_payload-${now}.zip`);
    });

  }, [userMnemonic, genCount, network, validatorIdx, validatorPassword, recoveryMode, signature, withdrawalMode, eth1Withdrawal]);

  const Footer = ({ _step, _setStep, ready, backDisabled }: { _step: number; _setStep: (v: number) => void; ready: boolean; backDisabled?: boolean }) => {
    return <div style={{
      width: '100%',
      height: 50,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}>
      {
        _step > 0 && !backDisabled

          ? <Button
            type={'text'}
            onClick={() => _setStep(_step - 1)}
          >Back</Button>

          : null
      }
      {
        _step < 5

          ? <Button
            disabled={!ready}
            type={'primary'}
            onClick={() => _setStep(_step + 1)}
          >Next</Button>

          : null
      }
    </div>
  }

  const toolsList = useMemo(() => {

    const versions = {
      ...getToolsVersions(),
      '@chainsafe/bls-keygen': manifest.dependencies['@chainsafe/bls-keygen'],
      '@chainsafe/bls': manifest.dependencies['@chainsafe/bls'],
      '@chainsafe/bls-keystore': manifest.dependencies['@chainsafe/bls-keystore'],
    };

    return Object.keys(versions).map((tool: string) => ({
      tool,
      version: versions[tool]
    }))

  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Popover placement="topLeft" title={`Tools & Versions`} content={() => {
        return <List
          dataSource={toolsList}
          renderItem={item => (
            <List.Item
              onClick={() => {
                window.open(`https://npmjs.com/package/${item.tool}`, '_blank')
              }}
              style={{
                cursor: 'pointer'
              }}
            >
              {item.tool} @ <span style={{ fontWeight: 700 }}>{item.version}</span>
            </List.Item>
          )}
        />
      }} trigger="hover">
        <InfoCircleOutlined
          style={{
            color: 'white',
            fontSize: 22,
            position: 'fixed',
            bottom: 16,
            left: 16,
            cursor: 'pointer'
          }}
        />
      </Popover>

      <Collapse accordion style={{ width: '100%', maxWidth: 700 }} activeKey={step}>
        <Collapse.Panel header="1. Welcome" key={0}>
          <Welcome network={network}/>
          <Footer
            ready={true}
            _step={0}
            _setStep={setStep}
          />
        </Collapse.Panel>
        <Collapse.Panel header="2. Recovery Mode" key={1}>
          <div
            style={{
              width: '100%',
              padding: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography.Title level={5}>Select Recovery Mode</Typography.Title>
            <Radio.Group
              options={[
                { label: 'Existing Mnemonic', value: 'user_mnemonic' },
                { label: 'New Mnemonic', value: 'new_mnemonic' },
                { label: 'Seed Signature', value: 'wallet_signature', disabled: true },
              ]}
              onChange={(v) => setRecoveryMode(v.target.value)}
              value={recoveryMode}
              optionType="button"
              buttonStyle={'solid'}
              size={'large'}
              style={{
                marginBottom: 16
              }}
            />
            <Typography.Text>{recoveryMode ? RecoveryModeDetails[recoveryMode].description : ''}</Typography.Text>
            <Divider orientation="left"></Divider>
            <Typography.Title level={5}>Select Withdrawal Mode</Typography.Title>
            <Radio.Group
              options={[
                { label: 'Ethereum Address', value: 'ethereum_address' },
                { label: 'Withdrawal Key', value: 'bls' }
              ]}
              onChange={(v) => setWithdrawalMode(v.target.value)}
              value={withdrawalMode}
              optionType="button"
              buttonStyle={'solid'}
              size={'large'}
              style={{
                marginBottom: 16
              }}
            />
            <Typography.Text>{withdrawalMode ? WithdrawalModeDetails[withdrawalMode].description : ''}</Typography.Text>
          </div>
          <Footer
            ready={!!recoveryMode}
            _step={1}
            _setStep={setStep}
          />
        </Collapse.Panel>
        <Collapse.Panel header={<span style={{color: withdrawalMode ? undefined : '#aaaaaa'}}>{`3. ${recoveryMode ? RecoveryModeDetails[recoveryMode].nextStep : 'Select Recovery Mode'}`}</span>} key={2}>
          <div
            style={{
              width: '100%',
              padding: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {
              recoveryMode === 'user_mnemonic'

                ? <>
                  <Input.TextArea
                    style={{
                      width: '80%'
                    }}
                    rows={4}
                    id="story"
                    name="story"
                    value={userMnemonic}
                    onChange={e => setUserMnemonic(e.target.value)}
                    showCount={{ formatter: () => `${userMnemonic === '' ? 0 : userMnemonic.split(' ').length}` }}
                  />
                  {
                    !validMnemonic && userMnemonic !== ''

                      ? <Typography.Text type={'danger'} style={{ marginTop: 16 }}>Invalid Mnemonic</Typography.Text>

                      : null
                  }

                </>

                : null
            }
            {
              recoveryMode === 'new_mnemonic'

                ? <>
                  <Input.TextArea
                    style={{
                      msUserSelect: 'none',
                      MozUserSelect: 'none',
                      WebkitUserSelect: 'none',
                      userSelect: 'none',
                      width: '80%'
                    }}
                    rows={4}
                    id="story"
                    name="story"
                    value={userMnemonic}
                  />
                  {
                    <Typography.Text type={'warning'} style={{ marginTop: 16 }}>Please note and keep this mnemonic in a safe place.</Typography.Text>
                  }
                  {
                    generateMnemonicError

                      ? <Typography.Text type={'danger'}>{generateMnemonicError}</Typography.Text>

                      : null
                  }

                </>

                : null
            }
            {
              recoveryMode === 'wallet_signature'

                ? <>

                  {
                    !active

                      ? <Button
                        type={'primary'}
                        onClick={() => activateBrowserWallet()}
                      >Connect Wallet</Button>

                      : <>
                        <Typography.Title level={5}
                          style={{
                            marginBottom: 16
                          }}
                        >Hello, {account}</Typography.Title>
                        {
                          signature

                            ? <Typography.Text type={'success'}> Signature Succesful</Typography.Text>

                            : <Button
                              onClick={() => signMessage()}
                              style={{
                                marginBottom: 16
                              }}
                              type={'primary'}
                            >Sign Proof</Button>
                        }
                        {
                          signatureError

                            ? <Typography.Text type={'danger'}>{signatureError}</Typography.Text>

                            : null
                        }
                      </>
                  }

                </>

                : null
            }
          </div>
          <Footer
            ready={(validMnemonic || (signature && !signatureError)) && !generateMnemonicError}
            _step={2}
            _setStep={setStep}
          />
        </Collapse.Panel>
        <Collapse.Panel header={<span style={{color: withdrawalMode ? undefined : '#aaaaaa'}}>{`4. ${withdrawalMode ? WithdrawalModeDetails[withdrawalMode].nextStep : 'Select Withdrawal Mode'}`}</span>} key={3}>
          <div
            style={{
              width: '100%',
              padding: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flexDirection: 'column',
              textAlign: 'center'
            }}
          >
            {
              withdrawalMode === 'ethereum_address'

                ? <div
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  <Form.Item
                    label="Ethereum Address"
                    style={{
                      width: '100%'
                    }}
                  >
                    <Input
                      min={1}
                      value={eth1Withdrawal}
                      onChange={(v) => setEth1Withdrawal(v.target.value)}
                    />
                  </Form.Item>
                  <Typography.Text>You will need this wallet to retrieve your funds</Typography.Text>
                </div>

                : null
            }
            {
              withdrawalMode === 'bls'

                ? <Typography.Text>The key to recover your funds can be retrieved with the same recovery method as the validator keys.</Typography.Text>

                : null
            }
          </div>
          <Footer
            ready={withdrawalMode === 'bls' || ethers.utils.isAddress(eth1Withdrawal)}
            _step={3}
            _setStep={setStep}
          />
        </Collapse.Panel>
        <Collapse.Panel header={`5. Key Count`} key={4}>
          <div
            style={{
              width: '80%',
              padding: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flexDirection: 'column'
            }}
          >
            {
              recoveryMode !== 'new_mnemonic'

                ?
                <Tooltip
                  title={'How many keys do you want to generate ?'}
                >
                  <Form.Item
                    label="Previous Keys"
                  >
                    <InputNumber
                      min={0}
                      value={validatorIdx}
                      onChange={(v) => setValidatorIdx(v)}
                      placeholder={'How many keys have you already generated with this recovery ?'}
                      style={{
                        width: 200
                      }}
                    />
                  </Form.Item>
                </Tooltip>

                : null
            }
            <Tooltip
              title={'How many keys do you want to generate ?'}
            >
              <Form.Item
                label="Key Count"
              >
                <InputNumber
                  placeholder={'How many keys do you want to generate ?'}
                  min={1}
                  value={genCount}
                  onChange={(v) => setGenCount(v)}
                  style={{
                    width: 200
                  }}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item
              label="Key Password"
            >
              <Input.Password
                min={1}
                value={validatorPassword}
                onChange={(v) => setValidatorPassword(v.target.value)}
                style={{
                  width: 200
                }}
              />
            </Form.Item>
            <Form.Item
              label="Verify Password"
            >
              <Input.Password
                min={1}
                value={validatorPasswordVerification}
                onChange={(v) => setValidatorPasswordVerification(v.target.value)}
                style={{
                  width: 200
                }}
              />
            </Form.Item>
            {
              validatorPasswordVerification && validatorPassword !== validatorPasswordVerification

                ? <Typography.Text type={'danger'}>Passwords not matching</Typography.Text>

                : null
            }
          </div>
          <Footer
            ready={validatorPassword && validatorPassword === validatorPasswordVerification}
            _step={4}
            _setStep={setStep}
          />
        </Collapse.Panel>
        <Collapse.Panel header={`6. Generate and Download keys`} key={5}>
          <div
            style={{
              width: '100%',
              padding: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Progress
              style={{
                width: '100%'
              }}
              percent={Math.floor((generationStep / (genCount + 1)) * 100)} />
            <div>
              <Button
                disabled={generationStep > 0}
                onClick={generate}
                type={'primary'}
                style={{
                  marginTop: 16,
                  marginRight: 8
                }}
              >Generate</Button>
              {
                generationStep === genCount + 1

                  ? <Button
                    onClick={redirect ? () => {
                      window.open(redirect, '_blank');
                      reset();
                    } : reset}
                  >{redirect ? 'Finish' : 'Restart'}</Button>

                  : null
              }
              <Button></Button>
            </div>
          </div>

          <Footer
            ready={validMnemonic || (signature && !signatureError)}
            backDisabled={generationStep > 0}
            _step={5}
            _setStep={setStep}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default App;
